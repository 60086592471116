.navigation-buttons {
    > * {
        position: relative;
        margin-left: 1em;
        &:before {
            content: "";
            display: inline-block;
            background: #0003;
            // flex: 0 0 2px;
            width: 1px;
            height: 1em;
            align-self: center;
            position: absolute;
            left: -0.5em;
            top: 50%;
            margin: -0.5em 0 0 -0.5px;
        }
    }

    > *:first-child:before {
        display: none;
    }
}