.data-grid {
    position: relative;
    min-height: 8rem;
 
    .data-grid-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: #FFF8;
        z-index: 10;
    }

    thead th {
        border-bottom: 3px solid #8885;
        vertical-align: top;

        .header-line {
            white-space: nowrap;
            overflow: hidden;
            flex-wrap: nowrap;
            display: flex;

            .header-label {
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &.sorted {
            border-bottom-color: #F90;
            background-color: #FFF !important;
        }

        i.bi {
            opacity: 0.5;

            &.bi-funnel {
                opacity: 0;
                transition: opacity 0.4s;
            }
        }

        &:hover i.bi-funnel {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }

    td.selected {
        background-color: #03D1;
    }

    .selection-checkbox {
        margin  : 0;
        width   : 1em;
        height  : 1em;
        position: relative;
        top     : 0.1em;
    }
}