.tree {
    // border: 1px solid #CCC;
    
    .grid {
        display: grid;
        // flex-wrap: wrap;
        // columns: auto;
        // grid-template-columns: minmax(20em, 1fr) minmax(20em, 1fr);
        // grid-auto-columns: minmax(auto, 1fr);
        // grid-template-columns: 1fr 1fr;
        gap: 2rem;
        // -webkit-column-rule: 5px #CCC dotted;
        // background: #CCC;
        // columns: 2;
        
        // column-rule-color: #CCC;
        // column-rule-width: 1px;
        // column-rule-style: solid;

        // .tree-item {
        //     // min-width: 20em;
        //     // background-color: #FFF;
        //     column-rule-color: #CCC;
        //     column-rule-width: 2px;
        //     column-rule-style: solid;
        // }

        .group {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    
    // .tree-item {
    //     flex: 1 1 40vw;
    // }


    // .tree {
    //     // margin: 0 0 0 1rem;
    //     column-span: 3;
    // }

    input.form-range {
        padding: 0.87rem 0;
        height: auto;
    }
}

@media (min-width: 768px) {
    .tree .grid {
        grid-template-columns: 1fr 1fr;
    }
}