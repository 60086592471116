.vm-collapse {

    display: block;
    // flex: 0 0 100%;
    // max-width    : 100%;
    
    .vm-collapse-header {
        margin     : 0;
        font-weight: bold;
        color      : var(--bs-primary-text-emphasis);
        cursor     : pointer;
        // padding    : 0.2em 0;
        text-shadow: 0 1px 0 #FFF8;
        user-select: none;
        // border-bottom: 1px dotted fade-out(#000, 0.7);
        // border-bottom: 3px solid #06F3;
        text-indent: 0;
        // background: linear-gradient(-15deg, #06F3, #03F0);
        // display: block;
        // max-width    : calc(100% - 1.4em);
        // overflow     : hidden;
        // text-overflow: ellipsis;
        // white-space  : nowrap;

        // > * {
        //     display: contents;
        // }

        // display    : grid;
        // grid       : auto-flow / auto auto 1fr;
        // align-items: center;
        // gap: 4px;
        // grid-template-columns: auto 1fr;

        padding-left: 14px;
        line-height: normal;
        // background  : orange;

        &:before {
            content       : "";
            display       : inline-block;
            vertical-align: middle;
            width         : 0;
            height        : 0;
            border-width  : 7px 6px 0;
            border-style  : solid solid none;
            border-color  : #0009 transparent transparent transparent;
            margin        : -2px 4px 0 -14px;
            // background  : #0003;
        }

        // > .bi {
        //     width: 1.2rem;
        //     text-align: center;
        //     color: #0008;
        //     display: none;//inline-block;
        //     margin-left: -1.4rem;
        //     margin-right: 0.2rem;
        // }
    }

    .vm-collapse-header {
        outline: 0;
        &:focus-visible {
            outline: 0;
            box-shadow: 0 0 0 1px #86b7fe, 0 0 0 .3rem #0d6efd40;
            border-radius: 5px;
            border-color: transparent;
        }
    }

    .vm-collapse-body {
        opacity: 1;
        overflow: visible;
        transform: none;
        transform-origin: 50% 0;
        transition: all .3s cubic-bezier(0.215, 0.610, 0.355, 1);
        min-height: 0 !important;
        height: auto;
        visibility: visible;
        padding-left: 16px;

        &.animating {
            overflow: hidden !important;
            visibility: visible !important;
        }
    }

    &.collapsed {
        .vm-collapse-header:before {
            border-width: 6px 0 6px 7px;
            border-style: solid none solid solid;
            border-color: transparent transparent transparent #0009;
            margin      : -3px 5px 0 -10px;
        }

        .vm-collapse-body {
            transform: scaleY(0.8);
            opacity: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}