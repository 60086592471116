.hr {
    height: 2px;
    background: currentColor;

    &.fade-in {
        background: linear-gradient(90deg, currentColor, transparent, currentColor);
    }
    &.fade-out {
        background: linear-gradient(90deg, transparent, currentColor, transparent);
    }
    &.fade-start {
        background: linear-gradient(90deg, transparent, currentColor);
    }
    &.fade-end {
        background: linear-gradient(90deg, currentColor, transparent);
    }
}