.breadcrumb {
    .breadcrumb-item {
        max-width: 30vw;
        a, span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}