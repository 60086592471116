:root {
  --color-brand-1: #08d;
  --color-brand-2: #3b3;
  --color-brand-3: #039;
  --color-brand-4: #e80;
}

.text-brand-1 {
  color: var(--color-brand-1) !important;
}

.text-brand-2 {
  color: var(--color-brand-2) !important;
}

.text-brand-3 {
  color: var(--color-brand-3) !important;
}

.text-brand-4 {
  color: var(--color-brand-4) !important;
}

.bg-brand-1 {
  background-color: var(--color-brand-1);
}

.bg-brand-2 {
  background-color: var(--color-brand-2);
}

.bg-brand-3 {
  background-color: var(--color-brand-3);
}

.bg-brand-4 {
  background-color: var(--color-brand-4);
}

.btn.btn-brand-1 {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #08d;
  --bs-btn-border-color: #0085d8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0078c4;
  --bs-btn-hover-border-color: #0069aa;
  --bs-btn-focus-shadow-rgb: 0, 136, 221;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #005f9b;
  --bs-btn-active-border-color: #005991;
  --bs-btn-focus-width: 3px;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #aaa;
  --bs-btn-disabled-bg: #ddd;
  --bs-btn-disabled-border-color: #999;
  text-shadow: 0 0 1px #0008;
}

.btn.btn-brand-1:disabled {
  text-shadow: none;
}

.btn.btn-brand-2 {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #3b3;
  --bs-btn-border-color: #32b732;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2ea72e;
  --bs-btn-hover-border-color: #289328;
  --bs-btn-focus-shadow-rgb: 51, 187, 51;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #258725;
  --bs-btn-active-border-color: #237f23;
  --bs-btn-focus-width: 3px;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #aaa;
  --bs-btn-disabled-bg: #ddd;
  --bs-btn-disabled-border-color: #999;
  text-shadow: 0 0 1px #0008;
}

.btn.btn-brand-2:disabled {
  text-shadow: none;
}

.btn.btn-brand-3 {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #039;
  --bs-btn-border-color: #003194;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #002b80;
  --bs-btn-hover-border-color: #026;
  --bs-btn-focus-shadow-rgb: 0, 51, 153;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #001d57;
  --bs-btn-active-border-color: #001a4d;
  --bs-btn-focus-width: 3px;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #aaa;
  --bs-btn-disabled-bg: #ddd;
  --bs-btn-disabled-border-color: #999;
  text-shadow: 0 0 1px #0008;
}

.btn.btn-brand-3:disabled {
  text-shadow: none;
}

.btn.btn-brand-4 {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #e80;
  --bs-btn-border-color: #e98500;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d57900;
  --bs-btn-hover-border-color: #bb6b00;
  --bs-btn-focus-shadow-rgb: 238, 136, 0;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #ac6200;
  --bs-btn-active-border-color: #a25c00;
  --bs-btn-focus-width: 3px;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #aaa;
  --bs-btn-disabled-bg: #ddd;
  --bs-btn-disabled-border-color: #999;
  text-shadow: 0 0 1px #0008;
}

.btn.btn-brand-4:disabled {
  text-shadow: none;
}

html body {
  overflow: auto !important;
}

#app {
  flex-direction: column;
  flex: 1;
  height: 100vh;
  display: flex;
}

#main {
  max-width: 100%;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  filter: grayscale();
}

.btn.btn-virtual:not(:hover, :active, :focus, :focus-visible) {
  color: #555 !important;
  background: none !important;
  border-color: #0000 !important;
}

.btn.btn-virtual:not(:hover, :active, :focus, :focus-visible) > .bi {
  color: #999 !important;
}

.btn.btn-virtual:hover, .btn.btn-virtual:focus, .btn.btn-virtual:focus-visible {
  background: #8881 !important;
  border-color: #00000008 !important;
}

.btn.btn-virtual:active {
  background: #8883 !important;
  border-color: #00000008 !important;
}

.navbar-toggler {
  border-color: #0000 !important;
  padding: 4px 10px !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width) transparent !important;
}

.offcanvas-header {
  z-index: 2;
  position: relative;
  box-shadow: 0 1px #0002;
}

.offcanvas-header .offcanvas-title {
  color: #777;
}

.offcanvas-lg {
  width: 14rem;
}

.vi-sidebar a:hover {
  background: #0001;
}

.page-header {
  text-align: center;
  color: #052c65;
  text-shadow: 1px 1px 1px #fff;
  padding: calc(1rem + 1vw);
}

.hr {
  background: currentColor;
  height: 2px;
}

.hr.fade-in {
  background: linear-gradient(90deg, currentColor, #0000, currentColor);
}

.hr.fade-out {
  background: linear-gradient(90deg, #0000, currentColor, #0000);
}

.hr.fade-start {
  background: linear-gradient(90deg, #0000, currentColor);
}

.hr.fade-end {
  background: linear-gradient(90deg, currentColor, #0000);
}

.vm-collapse {
  display: block;
}

.vm-collapse .vm-collapse-header {
  color: var(--bs-primary-text-emphasis);
  cursor: pointer;
  text-shadow: 0 1px #fff8;
  -webkit-user-select: none;
  user-select: none;
  text-indent: 0;
  margin: 0;
  padding-left: 14px;
  font-weight: bold;
  line-height: normal;
}

.vm-collapse .vm-collapse-header:before {
  content: "";
  vertical-align: middle;
  border: 6px solid #0000;
  border-top: 7px solid #0009;
  border-bottom: 0 #0000;
  width: 0;
  height: 0;
  margin: -2px 4px 0 -14px;
  display: inline-block;
}

.vm-collapse .vm-collapse-header {
  outline: 0;
}

.vm-collapse .vm-collapse-header:focus-visible {
  border-color: #0000;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0 0 1px #86b7fe, 0 0 0 .3rem #0d6efd40;
}

.vm-collapse .vm-collapse-body {
  opacity: 1;
  transform-origin: 50% 0;
  visibility: visible;
  height: auto;
  padding-left: 16px;
  transition: all .3s cubic-bezier(.215, .61, .355, 1);
  overflow: visible;
  transform: none;
  min-height: 0 !important;
}

.vm-collapse .vm-collapse-body.animating {
  visibility: visible !important;
  overflow: hidden !important;
}

.vm-collapse.collapsed .vm-collapse-header:before {
  border: 6px solid #0000;
  border-left: 7px solid #0009;
  border-right: 0 #0000;
  margin: -3px 5px 0 -10px;
}

.vm-collapse.collapsed .vm-collapse-body {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transform: scaleY(.8);
}

.type-ahead-component {
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  background-size: 22px 14px;
  width: 100%;
  display: inline-block;
  position: relative;
}

.type-ahead-component .type-ahead-component-value {
  padding-right: 2.4em;
  position: relative;
}

.type-ahead-component .select-component-placeholder {
  color: #999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.type-ahead-component .select-component-menu {
  z-index: 1000;
  background: #fff;
  border-radius: 3px;
  margin-top: 1px;
  padding: 3px;
  display: none;
  position: absolute;
  top: 100%;
  left: 1px;
  right: 1px;
  box-shadow: 0 0 0 1px #0003, 0 5px 10px -5px #00000080;
}

.type-ahead-component .select-component-menu .select-component-menu-wrap {
  max-height: 40vh;
  overflow: auto;
}

.type-ahead-component .select-component-menu.open {
  display: block;
}

.type-ahead-component .select-component-option {
  white-space: nowrap;
  cursor: default;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: .2em 12px;
  position: relative;
  overflow: hidden;
}

.type-ahead-component .select-component-option:hover {
  text-shadow: 0 1px #fff9;
  background: linear-gradient(90deg, #81abd64d, #81abd652);
  box-shadow: inset 0 0 0 1px #0001;
}

.type-ahead-component .select-component-option.selected {
  color: #fff;
  text-shadow: 0 1px #000c;
  background: linear-gradient(90deg, #369, #1c4f81);
  box-shadow: inset 0 0 0 .75px #036;
}

.type-ahead-component .select-component-option.disabled {
  color: #999;
  pointer-events: none;
}

.type-ahead-component .select-component-option .option-description {
  opacity: .5;
  white-space: normal;
  font-size: 86%;
}

.toggleWrap {
  display: contents;
}

.toggleWrap .toggle {
  vertical-align: middle;
  background: #ccc;
  border: 1px solid #0002;
  border-radius: 1em;
  flex: 0 0 2.5em;
  align-self: center;
  width: 3em;
  margin: .375rem .5rem;
  transition: all .2s linear;
  display: inline-block;
}

.toggleWrap .toggle:after {
  content: "";
  box-sizing: border-box;
  background: #fff;
  border-radius: 1em;
  width: 1.5em;
  height: 1.5em;
  margin: 1px;
  transition: transform .1s cubic-bezier(.17, .5, .88, .53);
  display: block;
  box-shadow: 0 0 0 1px #0002;
}

.toggleWrap .toggle.flip {
  background: currentColor;
}

.toggleWrap .toggle.flip:after {
  border-color: currentColor;
}

.toggleWrap .toggle.on {
  background: currentColor;
  border: 1px solid #0002;
}

.toggleWrap .toggle.on:after {
  border-color: currentColor;
  transform: translateX(calc(1.4em - 2px));
}

.toggleWrap .toggle:not(:focus-visible) {
  box-shadow: none !important;
}

.toggleWrap.disabled {
  color: #0006;
  pointer-events: none;
}

.toggleWrap.disabled .toggle {
  pointer-events: none;
  background: #8883;
}

.toggleWrap.disabled .toggle:after {
  background: #fff3;
}

.navigation-buttons > * {
  margin-left: 1em;
  position: relative;
}

.navigation-buttons > :before {
  content: "";
  background: #0003;
  align-self: center;
  width: 1px;
  height: 1em;
  margin: -.5em 0 0 -.5px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -.5em;
}

.navigation-buttons > :first-child:before {
  display: none;
}

.breadcrumb .breadcrumb-item {
  max-width: 30vw;
}

.breadcrumb .breadcrumb-item a, .breadcrumb .breadcrumb-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.loader-cover {
  text-align: center;
  z-index: 2;
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.data-grid {
  min-height: 8rem;
  position: relative;
}

.data-grid .data-grid-loader {
  z-index: 10;
  background: #fff8;
  place-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.data-grid thead th {
  vertical-align: top;
  border-bottom: 3px solid #8885;
}

.data-grid thead th .header-line {
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
}

.data-grid thead th .header-line .header-label {
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.data-grid thead th.sorted {
  border-bottom-color: #f90;
  background-color: #fff !important;
}

.data-grid thead th i.bi {
  opacity: .5;
}

.data-grid thead th i.bi.bi-funnel {
  opacity: 0;
  transition: opacity .4s;
}

.data-grid thead th:hover i.bi-funnel {
  opacity: .5;
}

.data-grid thead th:hover i.bi-funnel:hover {
  opacity: 1;
}

.data-grid td.selected {
  background-color: #03d1;
}

.data-grid .selection-checkbox {
  width: 1em;
  height: 1em;
  margin: 0;
  position: relative;
  top: .1em;
}

.tree .grid {
  gap: 2rem;
  display: grid;
}

.tree .grid .group {
  grid-column: 1 / 3;
}

.tree input.form-range {
  height: auto;
  padding: .87rem 0;
}

@media (width >= 768px) {
  .tree .grid {
    grid-template-columns: 1fr 1fr;
  }
}

#tooltip-container {
  z-index: 20000;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
}

#tooltip-container .tooltip {
  z-index: 100000;
  color: #fff;
  text-shadow: 0 0 1px #000c;
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(7px);
  background: #000a;
  border-radius: 4px;
  max-width: min(90vw, 450px);
  margin-top: -8px;
  font-size: 15px;
  transition: opacity .2s ease-in-out;
  position: fixed;
  box-shadow: 0 0 1px #fff8;
}

#tooltip-container .tooltip > .contents {
  z-index: 2;
  white-space: pre-wrap;
  padding: 5px 7px;
  line-height: normal;
  position: relative;
}

#tooltip-container .tooltip > .pointer {
  border: 5px solid #0000;
  border-top-color: #000a;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin: 0 0 -5px -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

#tooltip-container .tooltip.top-pointer {
  margin-top: 8px;
}

#tooltip-container .tooltip.top-pointer > .pointer {
  border-width: 5px;
  border-color: #0000 #0000 #0009;
  margin: -5px 0 0 -5px;
  top: -5px;
  left: 50%;
}

#tooltip-container .tooltip.no-pointer > .pointer {
  display: none;
}

.monitoring-page > section {
  background: linear-gradient(90deg, #0d6efd1c, #0d6efd00 50%);
  border-radius: 15px;
  flex-wrap: wrap;
  margin: 3rem 0;
  display: flex;
  position: relative;
}

.monitoring-page > section:nth-of-type(2n) {
  background: linear-gradient(90deg, #0d6efd00 50%, #0d6efd1c);
}

.monitoring-page > section:first-child {
  margin-top: 1rem;
}

.monitoring-page > section:hover {
  background-color: #0d6efd06;
  box-shadow: inset 0 0 0 1px #0d6efd1c;
}

.monitoring-page > section > h2 {
  color: #052c65;
  text-align: center;
  white-space: nowrap;
  text-shadow: 1px 1px #fff;
  border-radius: 15px 0 0 15px;
  flex: 1 1 0;
  align-content: center;
  margin: 0;
  padding: 3rem;
}

.monitoring-page > section > h2 i.bi {
  color: #3a3c;
  text-shadow: 2px 2px 3px #fffc, 0 0 #000;
  margin-right: 1rem;
  font-size: 125%;
}

.monitoring-page > section > div {
  flex: 3 1 0;
  align-content: center;
  padding: 3rem;
}

.monitoring-page > section:nth-of-type(2n) > h2 {
  order: 2;
}

@media (width <= 992px) {
  .monitoring-page > section {
    padding: 0 1rem;
    background: none !important;
  }

  .monitoring-page > section > h2 {
    text-align: left;
    border-bottom: 2px solid #393d;
    flex: 1 0 100%;
    padding: 1rem 0;
    background: none !important;
    border-radius: 0 !important;
    order: 0 !important;
  }

  .monitoring-page > section > div {
    width: 1 0 100%;
    padding: 1rem 0 !important;
  }

  .monitoring-page > section:hover {
    box-shadow: inset 0 0 0 1px #0d6efd2c;
    background: #0d6efd0c !important;
  }

  .monitoring-page .hr {
    display: none;
  }
}
/*# sourceMappingURL=index.04e172ae.css.map */
