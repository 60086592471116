.loader-cover {
    position: absolute;
    background: #FFF;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 2;
}