@use 'sass:color';

@function contrast($color, $threshold: 50%, $light: #FFF, $dark: #000) {
    @if (lightness($color) < $threshold) {
        @return $light;
    }
    @else {
        @return $dark;
    }
}

@mixin custom-button($color) {
    $fg: contrast($color);
    --bs-btn-font-weight : 600;
    --bs-btn-color       : #{$fg};
    --bs-btn-bg          : #{$color};
    --bs-btn-border-color: #{darken($color, 1%)};
    --bs-btn-hover-color : #{$fg};
    --bs-btn-hover-bg    : #{darken($color, 5%)};
    --bs-btn-hover-border-color: #{darken($color, 10%)};
    --bs-btn-focus-shadow-rgb: #{color.red($color), color.green($color), color.blue($color)};
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg   : #{darken($color, 13%)};
    --bs-btn-active-border-color: #{darken($color, 15%)};
    --bs-btn-focus-width : 3px;
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #AAA;
    --bs-btn-disabled-bg: #DDD;
    --bs-btn-disabled-border-color: #999;
    text-shadow: 0 0 1px #0008;
    &:disabled {
        text-shadow: none;
    }
}

$color-brand-1: #08D;
$color-brand-2: #3B3;
$color-brand-3: #039;
$color-brand-4: #E80;

:root {
    --color-brand-1: #{$color-brand-1};
    --color-brand-2: #{$color-brand-2};
    --color-brand-3: #{$color-brand-3};
    --color-brand-4: #{$color-brand-4};
}

.text-brand-1 { color: var(--color-brand-1) !important }
.text-brand-2 { color: var(--color-brand-2) !important }
.text-brand-3 { color: var(--color-brand-3) !important }
.text-brand-4 { color: var(--color-brand-4) !important }

.bg-brand-1 { background-color: var(--color-brand-1) }
.bg-brand-2 { background-color: var(--color-brand-2) }
.bg-brand-3 { background-color: var(--color-brand-3) }
.bg-brand-4 { background-color: var(--color-brand-4) }

.btn.btn-brand-1 { @include custom-button($color-brand-1); }
.btn.btn-brand-2 { @include custom-button($color-brand-2); }
.btn.btn-brand-3 { @include custom-button($color-brand-3); }
.btn.btn-brand-4 { @include custom-button($color-brand-4); }


html {
//     background: #FFF;
    body { overflow: auto !important; }
}


#app {
    flex: 1;
    flex-direction: column;
    display: flex;
    height: 100vh;
}

#main {
//     align-items: center;
//     justify-content: center;
    max-width: 100%;
    // overflow: visible;
    // overflow: hidden;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    filter: grayscale(1);
}
.btn.btn-virtual {
    &:not(:hover, :active, :focus, :focus-visible) {
        border-color: transparent !important;
        background: transparent !important;
        color: #555 !important;
        > .bi {
            color: #999 !important;
        }
    }
    &:hover, &:focus, &:focus-visible {
        border-color: #00000008 !important;
        background: #8881 !important;
    }
    &:active {
        border-color: #00000008 !important;
        background: #8883 !important;
    }
}

.navbar-toggler {
    padding: 4px 10px !important;
    border-color: transparent !important; // #0394;
    &:focus {
        box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width) transparent !important; // #0392 !important;
    }
    // outline: 0;
    // text-decoration: none;
}
// .offcanvas {
//     height: 100vh;
// }
.offcanvas-header {
    // border-bottom: 1px solid #0001;
    box-shadow: 0 1px 0 #0002;
    position: relative;
    z-index: 2;
    .offcanvas-title {
        color: #777;
    }
}

.offcanvas-lg {
    width: 14rem;
}
.vi-sidebar {
    // flex: 0 0 15rem;
    // .offcanvas-lg {
    //     pointer-events: none;
    //     &.show {
    //         pointer-events: all;
    //     }
    // }
    a {
        // padding: 0.25rem 1rem;
        &:hover {
            background: #0001;
        }
    }
}

// @media (min-width: 960px) {
//     .vi-sidebar {
// //         // background: #EEE;
// //         position: sticky;
// //         top: 3.5rem;
// //         display: block !important;
// //         max-height: calc(100vh - 3.5rem);
// //         // padding-left: .25rem;
// //         margin-left: -1rem;
// //         overflow-y: auto;
// //         // width: 20rem;
//         flex: 0;
//     }
// }
