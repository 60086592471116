.toggleWrap {
    display: contents;

    .toggle {

        display       : inline-block;
        vertical-align: middle;
        margin        : .375rem .5rem;
        width         : 3em;
        flex          : 0 0 2.5em;
        border-radius : 1em;
        background    : #CCC;
        align-self    : center;
        border        : 1px solid #0002;
        transition    : all 0.2s linear;
        
        &:after {
            content      : "";
            width        : 1.5em;
            height       : 1.5em;
            background   : #FFF;
            display      : block;
            border-radius: 1em;
            transition   : transform 0.1s cubic-bezier(.17,.5,.88,.53);
            box-sizing   : border-box;
            box-shadow   : 0 0 0 1px #0002;
            margin       : 1px;
        }

        &.flip {
            background: currentColor;
            &:after {
                border-color: currentColor;
            }
        }

        &.on {
            background: currentColor;
            border    : 1px solid #0002;
            &:after {
                border-color: currentColor;
                transform: translateX(calc(1.4em - 2px));
            }
        }
        
        &:not(:focus-visible) {
            box-shadow: none !important;
        }
    }

    &.disabled {
        color: #0006;
        pointer-events: none;
        .toggle {
            background: #8883;
            pointer-events: none;
            &:after {
                background: #FFF3;
            }
        }
    }
}
