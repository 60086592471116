#tooltip-container {
    position: fixed;
    z-index: 20000;
    overflow: visible;
    top: 0;
    left: 0;

    .tooltip {
        position   : fixed;
        z-index    : 100000;
        color      : #FFF;
        text-shadow: 0px 0px 1px #000c;
        transition : opacity 0.2s ease-in-out;
        margin-top : -8px;
        // font-family: Arial, 'Helvetica Neue', 'Open Sans',Tahoma, 'Arial', sans-serif;
        font-size  : 15px;
        opacity    : 0;
        max-width  : min(90vw, 450px);
        pointer-events : none;
        backdrop-filter: blur(7px);
        border-radius  : 4px;
        background: #000A;
        box-shadow: 0 0 1px 0 #FFF8;

        > .contents {
            position: relative;
            z-index: 2;
            padding: 5px 7px;
            line-height: normal;
            white-space: pre-wrap;
        }

        > .pointer {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-width: 5px 5px 0;
            border-color: #000A #0000 #0000;
            border-style: solid;
            margin: 0 0 -5px -5px
        }

        &.top-pointer {
            margin-top : 8px;
            
            > .pointer {
                top : -5px;
                left: 50%;
                margin: -5px 0 0 -5px;
                border-width: 5px;
                border-color: #0000 #0000 #0009;
            }
        }

        &.no-pointer > .pointer {
            display: none;
        }
    }
}