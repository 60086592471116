

.monitoring-page {

    > section {
        display: flex;
        margin: 3rem 0;
        flex-wrap: wrap;
        position: relative;
        border-radius: 15px;
        background: linear-gradient(90deg, #0d6efd1C, #0d6efd00 50%);

        &:nth-of-type(even) {
            background: linear-gradient(90deg, #0d6efd00 50%, #0d6efd1C);
        }

        &:first-child {
            margin-top: 1rem;
        }

        &:hover {
            background-color: #0d6efd06;
            box-shadow: 0 0 0 1px #0d6efd1C inset;
        }

        > h2 {
            margin: 0;
            padding: 3rem;
            color: #052c65;
            text-align:  center;
            align-content: center;
            white-space: nowrap;
            border-radius: 15px 0 0 15px;
            flex: 1 1 0;
            text-shadow: 1px 1px 0 #FFF;
            
            i.bi {
                font-size: 125%;
                margin-right: 1rem;
                // color: #0d6efdCC;
                color: #3A3C;
                text-shadow: 2px 2px 3px #FFFC, 0px 0px 0px #000;
            }
        }

        

        > div {
            align-content: center;
            flex: 3 1 0;
            padding: 3rem;
        }

        &:nth-of-type(even) {
            > h2 {
                order: 2;
            }
        }
    }

    
}
// .hr {
//     height: 2px;
//     background: linear-gradient(90deg, #0d6efd00, #0d6efd66, #0d6efd00);
// }

@media (max-width: 992px) {
    .monitoring-page {
        > section {
            background: transparent !important;
            padding: 0 1rem;
            > h2 {
                flex: 1 0 100%;
                text-align: left;
                border-radius: 0 !important;
                order: 0 !important;
                background: transparent !important;
                border-bottom: 2px solid #393D;
                padding: 1rem 0;
            }
            > div {
                width: 1 0 100%;
                padding: 1rem 0 !important;
            }

            &:hover {
                // box-shadow: none;
                box-shadow: 0 0 0 1px #0d6efd2C inset;
                background: #0d6efd0C !important;
            }
        }
        .hr {
            display: none;
        }
    }
}